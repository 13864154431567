import {
  CourseService,
  Enrollment,
  EnrollmentService,
  Level,
  Package,
  Program,
  Question,
  Status,
  Student,
  Test,
} from '../../lib';
import {
  GET_RANDOM_TODAY_EXERCISE_ON_WEAK_SYLLABLES,
  GET_STUDENT_ENROLLMENTS,
  SET_SELECTED_ENROLLMENT,
  SET_STUDENT_PLAN,
} from '../../constants';
import {
  doSelectQuestion,
  fetchSelectedStudent,
  isLoadingRequest,
  parseStringify,
  refreshUser,
} from './';
import {getDaysDiff} from '../../utils/Dates';
import {Alert} from 'antd';

const enrollmentService = new EnrollmentService();
const courseService = new CourseService();

export const setCurrentEnrollment = (enrollment: Enrollment | null) => {
  return {type: SET_SELECTED_ENROLLMENT, payload: enrollment};
};

export const setStudentsPlanMap = (plan: any) => {
  return {type: SET_STUDENT_PLAN, payload: plan};
};

export const setSelectedEnrollment = () => {
  return async (dispatch: any, getState: any) => {
    const selectedStudent: Student = getState().student.selectedStudent;
    const selectedProgram: Program = getState().program.selectedProgram;
    const languageTag: string = 'EN';
    selectedStudent.enrollments?.sort(
      (a: Enrollment | null, b: Enrollment | null) =>
        //@ts-ignore
        new Date(b.createdAt) - new Date(a.createdAt),
    );

    console.log('selectedStudent.enrollments11', selectedStudent);
    // @ts-ignore
    const paidEnrollment: Enrollment = selectedStudent.enrollments?.find(
      (enroll) =>
        !enroll?.package?.isFree && enroll?.paymentStatus === Status.DONE,
    );
    console.log('paidEnrollment000', paidEnrollment);
    if (paidEnrollment) {
      // @ts-ignore
      let currentSelectedPackage: Package = selectedProgram?.packages?.find(
        (pack) => pack.id === paidEnrollment.package?.id,
      );
      currentSelectedPackage.name = parseStringify(
        currentSelectedPackage.name_i18n,
        languageTag,
      );
      console.log('currentSelectedPackage', currentSelectedPackage);
      paidEnrollment.package = currentSelectedPackage;
      dispatch(setCurrentEnrollment(paidEnrollment));
    } else {
      console.log('FREE_ACKAEEEE');

      const currentFreePackage = selectedProgram?.packages?.find(
        (pack) => pack.isFree,
      );

      // @ts-ignore
      const currentFreeEnrollment: Enrollment =
        selectedStudent.enrollments?.find(
          (enroll) => enroll?.package?.id === currentFreePackage?.id,
        );
      dispatch(setCurrentEnrollment(currentFreeEnrollment));
    }
  };
};

export const getStudentTodayPlan = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));

    try {
      const selectedEnrollment = getState().enrollment.selectedEnrollment;
      const selectedStudent = getState().student.selectedStudent;
      const selectedProgram = getState().program.selectedProgram;

      console.log('CALLLLLLLL____11');

      console.log('currentEnrollment:', selectedEnrollment);
      let day = getDaysDiff(selectedEnrollment?.startDate ?? '');

      if (day === '0') {
        day = '1';
      }

      if (parseInt(day) <= 30) {
        console.log('SADSADday.toString()', day.toString());
        await dispatch(getStudentPlan(parseInt(day)));
      } else if (parseInt(day) >= 30 && selectedEnrollment?.package?.isFree) {
        await enrollmentService.updateStartDate(
          selectedEnrollment?.id,
          new Date().toISOString().split('T')[0],
          selectedEnrollment?.updatedAt,
        );

        await dispatch(refreshUser());
      } else if (parseInt(day) >= 30 && !selectedEnrollment?.package.isFree) {
        console.log(
          'selectedEnrollment?.package?.packageDuration',
          selectedEnrollment?.package,
        );
        console.log(
          'selectedEnrollment?.package?.packageDuration',
          selectedEnrollment?.package?.packageDuration,
        );
        console.log(
          'selectedEnrollment?.curriculum?.totalPlanPeriod',
          selectedEnrollment?.curriculum?.totalPlanPeriod,
        );

        if (
          selectedEnrollment?.package?.packageDuration &&
          parseInt(day) <= selectedEnrollment?.package?.packageDuration &&
          selectedEnrollment?.package?.packageDuration > 30
        ) {
          const planPeriod =
            selectedEnrollment?.curriculum?.totalPlanPeriod ?? 90;

          let enrollmentUpdatedDay = getDaysDiff(selectedEnrollment?.updatedAt);
          // const yearLevelInterval = [90, 180, 270, 360];

          //levelUp Enrollment if exceeds PlanPeriod if student in PikaDo curriculum only...
          if (
            parseInt(enrollmentUpdatedDay) > planPeriod &&
            (!selectedEnrollment?.curriculum?.id ||
              selectedEnrollment?.curriculum?.id === 'PIKADO')
          ) {
            const currentEnrollmentLevel: Level | null | undefined =
              selectedEnrollment.level;
            if (currentEnrollmentLevel) {
              let levelNumber: number = currentEnrollmentLevel.number ?? 0;
              levelNumber++;

              let levels = await courseService.getAllLevels();

              let newLevel: Level = levels?.find(
                (lvl: any) => lvl.number === levelNumber,
              );

              if (newLevel.id) {
                try {
                  await enrollmentService.levelUpEnrollment(
                    selectedEnrollment?.id,
                    newLevel.id,
                    selectedEnrollment?.updatedAt,
                  );
                } catch (error: any) {
                  Alert(error);
                }
              }
            }
          }
          let moduloDay = parseInt(day) % planPeriod;
          if (moduloDay === 0) {
            moduloDay = planPeriod;
          }
          console.log('moduloDay', moduloDay);

          await dispatch(getStudentPlan(moduloDay));
          return;
        }

        const currentFreePackage = selectedProgram?.packages?.find(
          (pack: Package) => pack.isFree,
        );

        await enrollmentService.createEnrollment({
          studentId: selectedStudent.id ?? '',
          packageId: currentFreePackage?.id,
          paymentStatus: Status.TODO,
          testId: selectedEnrollment.test?.id ?? '',
          selfRating: selectedEnrollment?.selfRating,
          placementStatus: Status.DONE,
          levelId: selectedEnrollment?.level?.id ?? 'FREE_LEVEL',
        });

        let dayy = getDaysDiff(new Date().toISOString().split('T')[0] ?? '');
        if (day === '0') {
          day = '1';
        }

        await dispatch(fetchSelectedStudent());
        await dispatch(getStudentPlan(parseInt(dayy)));
      }

      dispatch(isLoadingRequest(false));
    } catch (error: any) {
      dispatch(isLoadingRequest(false, error));
    }
  };
};

export const getStudentPlan = (day: number) => {
  return async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));

    try {
      const selectedEnrollment = getState().enrollment.selectedEnrollment;
      const selectedStudent = getState().student.selectedStudent;

      console.log('console.->fetchTODAY_PLAN->', selectedEnrollment);
      const res = await courseService.todayPlanByEnrollmentId(
        selectedEnrollment.id,
        day.toString(),
      );

      let tempStudentsPlan: any = [];
      tempStudentsPlan[selectedStudent?.id] = res;

      dispatch(setStudentsPlanMap(tempStudentsPlan));
    } catch (error: any) {
      console.log('error1->', error);
      dispatch(setStudentsPlanMap([]));
      dispatch(isLoadingRequest(false, error));
    } finally {
      dispatch(isLoadingRequest(false));
    }
  };
};

export const generateRandomExBasedOnWeakSyllables = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(isLoadingRequest(true));

    try {
      const selectedStudent: Student = getState().student.selectedStudent;
      const question: Question | null =
        await enrollmentService.getStudentRandomExercise(selectedStudent.id);
      dispatch(isLoadingRequest(false));

      dispatch(setRandomEx(question));
      dispatch(doSelectQuestion(question));
    } catch (error: any) {
      // crashlytics().recordError(error);
      dispatch(setRandomEx(null));
      dispatch(isLoadingRequest(false, new Error('Please, Try again')));
    }
  };
};

export const setRandomEx = (exData: Question | null | null) => {
  return {type: GET_RANDOM_TODAY_EXERCISE_ON_WEAK_SYLLABLES, payload: exData};
};
