import {
  SET_SELECTED_STUDENT,
  ADD_TO_PENDING_STUDENTS,
  ADD_SELECTED_PROGRAM,
  ADD_SELF_RATING,
  DELETE_STUDENT,
  SET_STUDENT_DETAILS,
  SET_PENDING_STUDENTS,
  SET_WAITING_STUDENT,
  RESET_STUDENT_STATE,
} from '../../constants';
import {Gender, Program, Student} from '../../lib';

export enum targetAge {
  LESS_THAN = 10,
  MORE_THAN = 16,
}

export type studentProgram = {
  isTestDone?: boolean;
  selfRating?: number | undefined;
} & Program;

export type StudentDetails = Student & {
  id: string;
  targetAge: targetAge.LESS_THAN | targetAge.MORE_THAN | number;
  kidName: string;
  dateOfBirth: string;
  gender: Gender;
  studentPrograms?: Array<studentProgram> | null;
};

export type StudentState = {
  selectedStudent: Student | null;
  studentsDetails: Array<Student>;
  pendingStudents: Array<StudentDetails>;
  waitingStudent: boolean;
};

export const initialState: StudentState = {
  selectedStudent: null,
  studentsDetails: [],
  pendingStudents: [],
  waitingStudent: false,
};

export default (state = initialState, action: any) => {
  const lastPendingStudent = state?.pendingStudents?.length - 1;
  switch (action.type) {
    case SET_STUDENT_DETAILS:
      return {
        ...state,
        studentsDetails: action.payload,
      };

    case SET_PENDING_STUDENTS:
      return {
        ...state,
        pendingStudents: action.payload,
      };

    case ADD_TO_PENDING_STUDENTS:
      return {
        ...state,
        pendingStudents: [...state.pendingStudents, action.payload],
      };

    case ADD_SELECTED_PROGRAM:
      return {
        ...state,
        pendingStudents: state.pendingStudents.map((student, i) =>
          i === lastPendingStudent
            ? {...student, studentPrograms: action.payload}
            : student,
        ),
      };

    case ADD_SELF_RATING: {
      return {
        ...state,
        pendingStudents: state.pendingStudents.map((student, i) =>
          i === lastPendingStudent
            ? {
                ...student,
                studentPrograms: student.studentPrograms?.map((lang, index) =>
                  index === action.payload.langIndex
                    ? {...lang, selfRating: action.payload.langLevel}
                    : lang,
                ),
              }
            : student,
        ),
      };
    }

    case DELETE_STUDENT:
      return {
        ...state,
        pendingStudents: action.payload,
      };

    case SET_SELECTED_STUDENT:
      return {
        ...state,
        selectedStudent: action.payload,
      };

    case SET_WAITING_STUDENT:
      return {
        ...state,
        waitingStudent: action.payload,
      };

    case RESET_STUDENT_STATE:
      return {
        selectedStudent: null,
        studentsDetails: [],
        pendingStudents: [],
        waitingStudent: false,
      };
    default:
      return state;
  }
};
