import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Typography as TypographyAnt} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {Enrollment, Status, Student} from '../../lib';
// import AdbIcon from '@mui/icons-material/Adb';
import Boy from '../../assets/Boy02.svg';
import icon_top from '../../assets/icon_top.svg';
import ToggleIcon from '../../assets/toggle_.svg';
import Logout from '../../assets/logout.svg';
import Pikado from '../../assets/PikaDoLogo.png';
import PikadoMobile from '../../assets/PikaDoLogoMobile.png';

import './appBar.css';

import {Link, useHistory, useLocation} from 'react-router-dom';
import {
  doSignOut,
  fetchSelectedStudent,
  getStudentTodayPlan,
  setSelectedStudent,
} from '../../redux/actions';
import {Loader} from '../Loader';

import ArrowDown from '../../assets/down-arrow.svg';
import {SettingsModal} from '../SettingsModal';

const settings = ['Subscription code', 'Sign out'];

const {Text} = TypographyAnt;

function ResponsiveAppBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location.pathname);

  const selectedStudent = useSelector(
    (state: any) => state.student.selectedStudent,
  );

  const selectedEnrollment: Enrollment | null = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );

  const studentsDetails = useSelector(
    (state: any) => state.student.studentsDetails,
  );

  const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false);

  const [isSettingsModalVisible, setIsSettingsModalVisible] =
    React.useState<boolean>(false);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (index: number) => {
    setAnchorElNav(null);

    console.log('SSS');
    if (index === 0) {
      setIsSettingsModalVisible(true);
    } else if (index === 1) {
      //@ts-ignore
      dispatch(doSignOut());
    }
  };

  const handleCloseUserMenu = async (s: Student) => {
    setAnchorElUser(null);
    setIsLoadingData(true);
    //@ts-ignore
    await dispatch(setSelectedStudent(s));
    //@ts-ignore
    await dispatch(fetchSelectedStudent());
    //@ts-ignore
    await dispatch(getStudentTodayPlan());

    setIsLoadingData(false);
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;
    if (string) {
      /* eslint-disable no-bitwise */
      for (i = 0; i < string?.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = '#';

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
    }
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        width: 35,
        height: 35,

        bgcolor: stringToColor(name),
      },
      children: `${
        name?.split(' ') ? name?.split(' ')[0][0] : name?.substring(0, 1)
      }`,
    };
  };

  return (
    <AppBar
      position="sticky"
      style={{
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottomWidth: 1,
        borderBottomColor: '#F1F1F1',
        borderBottomStyle: 'solid',
        zIndex: 1000,
      }}
      color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}} /> */}

          <img
            onClick={() => {
              if (location?.pathname !== '/home') {
                history.goBack();
              }
            }}
            className="pikado-image"
            alt="s"
            src={Pikado}
            style={{
              resize: 'inline',
              marginInlineEnd: 20,
              width: '12%',
            }}
          />
          <img
            onClick={() => {
              if (location?.pathname !== '/home') {
                history.goBack();
              }
            }}
            className="pikado-image-mobile"
            alt="s"
            src={PikadoMobile}
            style={{
              resize: 'inline',
              marginInlineEnd: 20,
              width: '12%',
            }}
          />
          <div className="welcome-container">
            <Text
              className="header-date"
              style={{
                display: 'inline-block',
                fontFamily: 'Arial Rounded MT Bold',
                color: '#F2406A',
                fontWeight: 'bold',
              }}>
              {new Date().toDateString()}
            </Text>

            <Text
              className="welcome-title"
              style={{
                display: 'inline-block',
                fontFamily: 'Arial Rounded MT Bold',
                marginLeft: 10,
              }}>
              {`Welcome,  ${selectedStudent?.name}`}
            </Text>
          </div>
          {location?.pathname === '/home' && (
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}></Box>
              {!selectedEnrollment?.package?.isFree &&
                selectedEnrollment?.paymentStatus === Status.DONE && (
                  <Box>
                    <Tooltip title="Your Report">
                      <Link to={`/report/${selectedStudent.id}`}>
                        <IconButton
                          size="medium"
                          aria-label="report"
                          color="inherit">
                          <img
                            src={icon_top}
                            style={{
                              display: 'inline-block',
                            }}
                            onClick={() => {}}
                          />

                          <Typography
                            textAlign="center"
                            // variant="h6"
                            noWrap
                            style={{
                              fontSize: 16,
                              color: '#40B2F2',
                              fontFamily: 'Arial Rounded MT Bold',
                              fontWeight: 400,
                              marginInline: '10px',
                            }}
                            // href="#app-bar-with-responsive-menu"
                          >
                            <span className="report-title">Report</span>
                          </Typography>
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </Box>
                )}
              <Box sx={{flexGrow: 0}}>
                <Tooltip title="settings">
                  <IconButton onClick={handleOpenNavMenu} sx={{p: 0}}>
                    <Avatar
                      alt="s"
                      src={ArrowDown}
                      sx={{width: 35, height: 35}}
                      style={{
                        marginInline: 8,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{mt: '45px'}}
                  id="menu-settings-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={() => {
                    setAnchorElNav(null);
                  }}>
                  {settings.map((s: string, index: number) => (
                    <MenuItem
                      key={s}
                      onClick={(e) => handleCloseNavMenu(index)}>
                      <Typography
                        textAlign="center"
                        style={{
                          fontFamily: 'Arial Rounded MT Bold',
                          fontSize: 14,
                          color: '#000',
                          //   fontWeight: 600,
                        }}>
                        {index === 0 ? (
                          <img
                            src={ToggleIcon}
                            style={{
                              display: 'inline-block',
                              width: 20,
                              height: 16,
                              marginInlineEnd: 7,
                            }}
                          />
                        ) : (
                          <img
                            src={Logout}
                            style={{
                              display: 'inline-block',
                              width: 20,
                              height: 16,
                              marginInlineEnd: 7,
                            }}
                            onClick={() => {}}
                          />
                        )}
                        {s}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box sx={{flexGrow: 0}}>
                <Tooltip title="profiles">
                  <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    <Avatar alt="" {...stringAvatar(selectedStudent?.name)} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{mt: '45px'}}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={() => {
                    setAnchorElUser(null);
                  }}>
                  {studentsDetails.map((s: Student) => (
                    <MenuItem
                      key={s.id}
                      onClick={() => handleCloseUserMenu(s)}
                      disabled={selectedStudent?.id === s.id}>
                      <Typography textAlign="center">{s?.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          )}
        </Toolbar>
        {isSettingsModalVisible && (
          <SettingsModal
            closeModal={() => {
              console.log('SSS,');
              setIsSettingsModalVisible(false);
            }}
          />
        )}
      </Container>

      {isLoadingData ? <Loader title={'Loading...'} /> : null}
    </AppBar>
  );
}
export default ResponsiveAppBar;
