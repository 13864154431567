import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {createStore, applyMiddleware} from 'redux';
// import { createLogger } from 'redux-logger';
import {persistStore, persistReducer} from 'redux-persist';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import {rootReducer} from '../reducers'; // the value from combineReducers

const middleware: any = [];

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['user', 'appState', 'auth', 'test', 'student', 'program'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware, thunk)),
);
const persistor = persistStore(store);

export {store, persistor};
