import {useEffect, useState} from 'react';
import {Link, Switch, useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Alert, Button, Space, Spin} from 'antd';
import {Route} from 'react-router-dom';

import {SignIn} from '../components';
import 'react-toastify/dist/ReactToastify.css';
import {AuthenticationPage, HomePage, ReportPage} from '../pages';
import {
  doSignInWithPartner,
  doSignOut,
  isLoadingRequest,
  refreshUser,
} from '../redux/actions';
import {PikaDoPartners} from '../lib';
import InterCom from '../pages/Intercom';
import {Grid, Typography} from '@mui/material';

export const Routers = () => {
  const history = useHistory();

  const isLoadingLogIn = useSelector(
    (state: any) => state.appState.isLoadingLogIn,
  );
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  const isLoading = useSelector((state: any) => state.appState?.isLoading);

  const isLoggedInAsPartner = useSelector(
    (state: any) => state.auth.partnerUser,
  );

  const dispatch = useDispatch();

  const location: {
    state: any;
    hash: any;
    search: string;
    pathname: string;
  } = useLocation();

  useEffect(() => {
    console.log('router', location);
    // ?student_id:ecops2243caseeq231zx1?partnerUser=CLASSERA
    if (
      location?.search?.includes('partner:') &&
      location?.search?.includes('token:') &&
      !isLoggedInAsPartner
    ) {
      const arrState = location.search.split('?');
      const partnerFounded = arrState.find((value: string) =>
        value.startsWith('partner:'),
      );
      if (arrState && partnerFounded) {
        const partnerId: any = partnerFounded?.split(':')[1];
        console.log('partnerFounded', partnerId);

        dispatch(
          //@ts-ignore
          doSignInWithPartner(
            {
              url: location.search,
            },
            partnerId,
          ),
        );
      }
    } else if (isLoggedIn || isLoggedInAsPartner) {
      //@ts-ignore
      dispatch(refreshUser());
      location.pathname === '/' && history.push('/home');
    }
  }, [dispatch, isLoggedIn, isLoggedInAsPartner]);
  console.log('isLoggedIn', isLoggedIn);
  /*
      const currentUser = useSelector(
        (state: reducersState) => state.auth.currentUser,
      );*/

  // const error = useSelector((state: reducersState) => state.auth.error);

  /*useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUser());
      dispatch(fetchReviewQuestions());
    }
  }, [dispatch, isLoggedIn]);
*/

  return (
    <div>
      {!isLoggedIn ? (
        <AuthenticationPage />
      ) : (
        <>
          <Route path="/home" component={HomePage} />
          <Route exact path="/report/:studentId" component={ReportPage} />
        </>
      )}

      {isLoadingLogIn && (
        <Space
          direction="vertical"
          style={{
            width: '100%',
            top: '50%',
            backgroundColor: 'red',
            position: 'absolute',
          }}>
          <Spin tip="Loading">
            <div className="content" />
          </Spin>
        </Space>
      )}

      <InterCom />
    </div>
  );
};
