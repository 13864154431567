import {
  SET_SELECTED_STUDENT,
  SET_STUDENT_DETAILS,
} from '../../constants/Actions';

import {
  Asset,
  Gender,
  Package,
  Program,
  Student,
  StudentService,
} from '../../lib';
import {setSelectedProgram} from './program';
import {setSelectedEnrollment} from './enrollment';
import {isLoadingRequest} from './appState';

const studentService = new StudentService();

export const fetchStudents = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const students: Array<Student> = await studentService.getStudents();
      await dispatch(setStudentsDetails(students));
      //dispatch(updatePendingStudents());
    } catch (error: any) {
      console.log('Error fetchingStudent: ', error);
    }
  };
};

export const setStudentsDetails = (students: Array<Student>) => {
  return {
    type: SET_STUDENT_DETAILS,
    payload: students,
  };
};

export const setSelectedStudent =
  (student: Student) => async (dispatch: any) => {
    try {
      dispatch({type: SET_SELECTED_STUDENT, payload: student});
    } catch (error) {
      console.log('SET_SELECTED_STUDENT error: ', error);
    }
  };

export const parseStringify = (text: any, languageTag: string) => {
  return JSON.parse(text)[languageTag] ?? '';
};

export const fetchSelectedStudent = () => {
  return async (dispatch: any, getState: any) => {
    // try {
    const id = getState().student.selectedStudent.id;
    const languageTag: string = 'EN';
    const selectedProgram: Program = getState().program.selectedProgram;
    const student: Student = await studentService.getStudentById(id);

    const enrollIndexOfSelectedProgram = student?.enrollments?.findIndex(
      (enroll) => enroll?.package?.program.id === selectedProgram.id,
    );
    // if enrollIndexOfSelectedProgram -1  it means selectedStudent switched and his program changed so bydefault fallback to first enrollment and so on fallback to first package and program

    const index =
      enrollIndexOfSelectedProgram !== -1 ? enrollIndexOfSelectedProgram : 0;

    //@ts-ignore
    let program: Program = student?.enrollments[index]?.package?.program;

    if (!program?.packages) {
      const programList = getState().program.programList;
      program = programList?.length > 0 ? programList[0] : [];
    }

    const packages: Array<Package> = program?.packages?.map((pack: Package) => {
      return {
        ...pack,
        name: parseStringify(pack?.name_i18n ?? '{}', languageTag) ?? '',
        benefits:
          parseStringify(pack?.benefits_i18n ?? '{}', languageTag) ?? '',
        records:
          //@ts-ignore
          pack?.records?.map((record: Asset) => {
            return {
              ...record,
              //@ts-ignore
              title: JSON.parse(record.title_i18n ?? '')[languageTag] ?? '',
              //@ts-ignore
              subTitle:
                JSON.parse(record.subTitle_i18n ?? '')[languageTag] ?? '',
            };
          }) || [],
        activities:
          //@ts-ignore
          pack?.activities?.map((activity: Asset) => {
            return {
              ...activity,
              //@ts-ignore
              title: JSON.parse(activity?.title_i18n ?? '')[languageTag] ?? '',
              //@ts-ignore
              subTitle:
                JSON.parse(activity?.subTitle_i18n ?? '')[languageTag] ?? '',
            };
          }) || [],
        details: parseStringify(pack?.details_i18n ?? '{}', languageTag) ?? '',
        description:
          parseStringify(pack?.description_i18n ?? '{}', languageTag) ?? '',
      };
    });

    program.packages = packages;

    dispatch(setSelectedProgram(program));
    dispatch(setSelectedStudent(student));
    dispatch(setSelectedEnrollment());
  };
};

export const applySubscriptionPromoCode = (
  codeName: string,
  studentId: string,
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const studentName = getState().student.selectedStudent.name;
      dispatch(isLoadingRequest(true));
      await studentService.applySubscriptionPromoCode(codeName, studentId);

      await dispatch(fetchStudents());
      await dispatch(fetchSelectedStudent());
      await dispatch(setSelectedEnrollment());
      dispatch(isLoadingRequest(false));

      alert(studentName + ' ' + 'Subscribed Successfully');
    } catch (err: any) {
      dispatch(isLoadingRequest(false));
      alert(err.errors[0].message);
    }
  };
};
