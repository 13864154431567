import startbutton from '../../assets/startbutton.svg';
import Button from 'react-bootstrap/Button';
import microfon from '../../assets/microfon.svg';
import React from 'react';
import {AssetType, Question} from '../../lib';
import './index.css';
import BasicImageDisplayAuthS3 from '../ImageCard/BasicImageDisplayAuthS3';

type QuestionCardType = {
  question: Question;
  index: number;
  onClick: () => void;
  isBlur: boolean;
};

export const QuestionCard = ({
  question,
  index,
  onClick,
  isBlur = false,
}: QuestionCardType) => {
  return (
    <div
      className="exercise-card"
      key={index}
      id={`${question.id}`}
      onClick={() => {
        if (!isBlur) {
          onClick();
        }
      }}>
      <div className={`card-flyer card-question ${isBlur && 'blur'}`}>
        <div className="text-box">
          <div className="image-box">
            <BasicImageDisplayAuthS3
              thumbnailUri={question?.asset?.thumbnailUri}
            />
            {question?.asset?.type === AssetType.VIDEO && (
              <img className="img-fluid startbutton" src={startbutton} />
            )}
          </div>
          <div className="text-container">
            <h6>{question?.name}</h6>
            <p>{question?.summary}</p>
            <Button
              type="button"
              className="btn-record"
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
              style={{marginTop: 15}}
              onClick={() => {
                if (!isBlur) {
                  onClick();
                }
              }}>
              <img src={microfon} alt="" />
              <span> Answer question</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
