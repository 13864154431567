import {IAuthenticationService} from './interfaces';
import {AuthenticationRepository} from '../repositories';

export class AuthenticationService implements IAuthenticationService {
  authRepository: AuthenticationRepository;

  constructor() {
    this.authRepository = new AuthenticationRepository();
  }

  async signUp(email: string, password: string): Promise<any> {
    return await this.authRepository.signUp(email, password);
  }

  async signIn(email: string, password: string): Promise<any> {
    return await this.authRepository.signIn(email, password);
  }

  async validatePartnerAuthUser(
    partnerAppName: string,
    studentId: string,
  ): Promise<any> {
    return await this.authRepository.validatePartnerAuth(
      partnerAppName,
      studentId,
    );
  }

  async signOut(): Promise<any> {
    return await this.authRepository.signOut();
  }

  forgetPassword(email: string): Promise<any> {
    return Promise.resolve(undefined);
  }
}
