import {Typography} from 'antd';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  Loader,
  ProgressModal,
  QuestionCard,
  TestCard,
  TestModal,
} from '../../components';

import './HomePage.scss';

import {ExerciseModal} from '../../components/ExerciseModal';
import {
  AssetType,
  ProgramType,
  QUESTION_TYPE,
  Question,
  Status,
  Test,
} from '../../lib';
import {
  doClearTest,
  doSelectQuestion,
  doSelectTest,
  generateRandomExBasedOnWeakSyllables,
  setCurrentTest,
} from '../../redux/actions';

import Title from 'antd/es/typography/Title';
import moment from 'moment';
import ResponsiveAppBar from '../../components/Appbar';
import FooterBar from '../../components/Footerbar';
import {getDaysDiff} from '../../utils/Dates';

const openAIQuestionAsset: Question = {
  id: '-OPEN-AI',
  createdAt: 'Node.generateCreatedAt()',
  updatedAt: 'Node.generateUpdatedAt()',
  name: 'Daily Reading Paragraph',
  name_i18n: '',
  programType: ProgramType.LANGUAGE,
  summary:
    'Press the below button to generate a reading paragraph depends on your repeated syllables mistakes.',
  summary_i18n: '',
  description:
    'Please press the recording button and read the below sentence in a clear voice.',
  description_i18n: '',
  text: 'selectedSentence',
  asset: {
    __typename: 'Asset',
    id: 'adsss',
    createdAt: '',
    updatedAt: '',
    type: AssetType.PHOTO,
    thumbnailUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    fileUri:
      'https://s3.eu-west-1.amazonaws.com/assets.pikado.io/photo/337778904_1074114464030879_8325695208240193271_n.jpg',
    title_i18n: '',
    subTitle_i18n: '',
    // position?: number ,
  },
  choices: [],
  keywords: [],
  textAI: 'selectedSentence?.toUpperCase()',
  type: QUESTION_TYPE.READ_SENTENCE,
  __typename: 'Question',
};

export const HomePage = () => {
  const dispatch = useDispatch();
  const {Text} = Typography;
  const isLoading = useSelector((state: any) => state.appState.isLoading);
  const todayRandomExercise: Question | null = useSelector(
    (state: any) => state.enrollment.todayRandomExercise,
  );
  const selectedStudent = useSelector(
    (state: any) => state.student.selectedStudent,
  );

  const studentsDetails = useSelector(
    (state: any) => state.student.studentsDetails,
  );

  const [isError, setIsError] = useState<boolean>(false);

  const [isProgressModalVisible, setIsProgressModalVisible] =
    useState<boolean>(false);

  const studentsPlanMap = useSelector(
    (state: any) => state.enrollment.studentPlan,
  );
  const [openExerciseModal, setOpenExerciseModal] = useState<boolean>(false);
  const [openTestModal, setOpenTestModal] = useState<boolean>(false);
  const [planDay, setPlanDay] = useState<string>();

  const selectedEnrollment = useSelector(
    (state: any) => state.enrollment.selectedEnrollment,
  );

  useEffect(() => {
    if (selectedEnrollment?.startDate) {
      let day = getDaysDiff(selectedEnrollment?.startDate ?? '');

      if (day === '0') {
        day = '1';
      }

      setPlanDay(day);
    }
  }, [selectedEnrollment]);

  const isEnrollmentReady = () => {
    if (Object.keys(studentsPlanMap)?.length !== 0 && selectedStudent) {
      return true;
    } else if (
      Object?.keys(studentsPlanMap)?.length !== 0 &&
      selectedStudent &&
      selectedEnrollment?.package.isFree
    ) {
      return true;
    }
  };

  const renderCard = (test: Test, index: number) => {
    if (test?.questions?.length! > 1 && test?.type !== 'QUIZ') {
      return (
        <div
          style={{display: 'contents'}}
          className="col-xs-12 col-sm-6 col-md-6 col-lg-4"
          key={`selectedMapId_${index}`}>
          <TestCard
            //@ts-ignore
            test={test}
            index={index}
            //@ts-ignore
            isBlur={
              (selectedEnrollment?.package?.isFree ||
                selectedEnrollment.paymentStatus !== Status.DONE) &&
              index > 1
                ? true
                : false
            }
            onClick={() => {
              //@ts-ignore
              dispatch(doSelectTest(test));
              setOpenTestModal(true);
            }}
          />
          {/*@ts-ignore*/}
          {/* {test?.questions?.map((q: Question, questionIndex: number) => {
            return (
              <QuestionCard
                key={q.id}
                //@ts-ignore
                question={q}
                //@ts-ignore
                isBlur={
                  selectedEnrollment.package.isFree && questionIndex > 0
                    ? true
                    : false
                }
                index={index}
                onClick={() => {
                  //@ts-ignore
                  if (test.questions?.length === 1) {
                    //TODO: Fix questions and tests
                    //@ts-ignore
                    dispatch(doSelectQuestion(test.questions[0]));
                  } else {
                    //@ts-ignore
                    dispatch(doSelectQuestion(q));
                  }
                  setOpenExerciseModal(true);
                }}
              />
            );
          })} */}
        </div>
      );
    } else {
      return (
        <div
          className="col-xs-12 col-sm-6 col-md-4 col-lg-4"
          style={{display: 'contents'}}
          key={`selectedMapId_${index}`}>
          <QuestionCard
            //@ts-ignore
            isBlur={
              (selectedEnrollment?.package?.isFree ||
                selectedEnrollment.paymentStatus !== Status.DONE) &&
              index > 0
                ? true
                : false
            }
            //@ts-ignore
            question={test}
            index={index}
            onClick={() => {
              //@ts-ignore
              if (test?.questions?.length === 1) {
                //TODO: Fix questions and tests
                //@ts-ignore
                dispatch(doSelectQuestion(test.questions[0]));
              } else {
                //@ts-ignore
                dispatch(doSelectQuestion(test));
              }
              setOpenExerciseModal(true);
            }}
          />
        </div>
      );
    }
  };

  const renderTodayPlan = () => {
    return studentsPlanMap[selectedStudent?.id]?.map(
      (test: Test, index: number) => {
        return renderCard(test, index);
      },
    );
  };

  return (
    <div>
      {!openExerciseModal && !openTestModal && <ResponsiveAppBar />}
      <div id="cards_landscape_wrap-2">
        <div className="container">
          <div className="row" style={{justifyContent: 'space-between'}}>
            <Typography
              color="textSecondary"
              // variant="subtitle1"
              style={{
                fontFamily: 'Tajawal',
                fontWeight: 'bold',
                fontSize: 13,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                marginInline: 20,
              }}>
              Day: {planDay}
            </Typography>

            {isEnrollmentReady() && !isLoading && (
              <QuestionCard
                //@ts-ignore
                isBlur={
                  selectedEnrollment?.package?.isFree ||
                  selectedEnrollment.paymentStatus !== Status.DONE
                    ? true
                    : false
                }
                //@ts-ignore
                question={openAIQuestionAsset}
                index={10}
                onClick={async () => {
                  const createdAt = moment(todayRandomExercise?.createdAt);
                  const today = moment();
                  // today.add(1, 'days');
                  if (
                    !todayRandomExercise ||
                    !today?.isSame(createdAt, 'days')
                  ) {
                    //@ts-ignore
                    await dispatch(generateRandomExBasedOnWeakSyllables());
                    // if (todayRandomExercise) {
                    setOpenExerciseModal(true);
                    //}
                  } else {
                    //@ts-ignore
                    dispatch(doSelectQuestion(todayRandomExercise));
                    setOpenExerciseModal(true);
                  }
                }}
              />
            )}
            {isEnrollmentReady() && renderTodayPlan()}
          </div>
        </div>
        {openExerciseModal && (
          <ExerciseModal
            closeModal={() => {
              //@ts-ignore
              dispatch(doClearTest());

              setOpenExerciseModal(false);
            }}
          />
        )}

        {openTestModal && (
          <TestModal
            onQuestionsClick={() => {
              setOpenExerciseModal(true);
              setOpenTestModal(false);
            }}
            closeModal={() => {
              //@ts-ignore
              dispatch(doClearTest());
              setOpenTestModal(false);
            }}
          />
        )}
      </div>
      {isProgressModalVisible && (
        <ProgressModal
          onCloseProgressModal={() => setIsProgressModalVisible(false)}
        />
      )}

      {isLoading ? <Loader title={'Loading...'} /> : null}

      {isError && <Title>Exercises fetching error, try again ⚠️</Title>}

      {isEnrollmentReady() && !isError && <FooterBar />}
    </div>
  );
};
