import {IStudentService} from './interfaces';
import {Gender, Student} from '../API';
import {StudentRepository} from '../repositories';

export class StudentService implements IStudentService {
  studentRepository: StudentRepository;

  constructor() {
    this.studentRepository = new StudentRepository();
  }

  async getStudents(): Promise<Array<Student>> {
    const students = await this.studentRepository.getStudents();
    return students;
  }

  async getStudentById(id: string): Promise<Student> {
    const student = await this.studentRepository.getStudentById(id);
    return student;
  }

  async updateStudent(student: Student): Promise<any> {
    const updatedStudent = await this.studentRepository.updateStudent(student);
    return updatedStudent;
  }

  async applySubscriptionPromoCode(
    codeName: string,
    studentId: string,
  ): Promise<any> {
    return await this.studentRepository.applySubscriptionPromoCode(
      codeName,
      studentId,
    );
  }
}
