import {AuthenticationService} from '../../lib/services';
import {
  IS_LOGGED_IN,
  RESET_STUDENT_STATE,
  SIGN_IN_SUCCESS,
  SIGN_IN__WITH_PARTNER_SUCCESS,
  SIGN_OUT_ERROR,
} from '../../constants';
import {getUser} from './user';
import type {PartnerUser} from '../reducers/auth';
import {Amplify} from 'aws-amplify';
import awsmobile from '../../amplifyconfiguration.json';

import {
  fetchStudents,
  setSelectedStudent,
  fetchSelectedStudent,
} from './student';
import {fetchProgramsDetails} from './program';
import {isLoadingLogInRequest, isLoadingRequest} from './appState';
import {store} from '../store';
import {AuthenticatorResponse, PikaDoPartners} from '../../lib';
import {getStudentTodayPlan} from './enrollment';
import {notification} from 'antd';
import {decodeToken} from 'react-jwt';
import {fetchUserAttributes, rememberDevice} from 'aws-amplify/auth';
store.subscribe(getPartnerAuthToken);

const authenticationService = new AuthenticationService();

export const setSignInPartner =
  (partUser: PartnerUser) => async (dispatch: any) => {
    console.log('from actions ->', partUser);
    try {
      dispatch({type: SIGN_IN__WITH_PARTNER_SUCCESS, payload: partUser});
    } catch (error) {
      console.log('SIGN_IN_WITH_PARTNER_SUCCESS error: ', error);
    }
  };

export const doSignIn =
  (email: string, password: string) => async (dispatch: any, getState: any) => {
    configAmplifyCognitoRequest();
    dispatch(isLoadingRequest(true));
    try {
      let response = await authenticationService.signIn(email, password);
      const authData = await fetchUserAttributes();
      dispatch(getUser());

      await dispatch(fetchStudents());
      const studentsDetails = getState().student.studentsDetails;
      await dispatch(setSelectedStudent(studentsDetails[0]));
      await dispatch(fetchSelectedStudent());
      await dispatch(fetchProgramsDetails());
      dispatch({type: SIGN_IN_SUCCESS, payload: authData});
      dispatch(isLoadingRequest(false));
      //browserHistory.push("/home")
    } catch (error: any) {
      console.log('SIGN_IN_ERROR1', error);
      alert(error);
      dispatch(isLoadingRequest(false, error));
    }
  };

export const doSignOut = () => async (dispatch: any) => {
  try {
    dispatch({type: 'SIGN_OUT'});
    await authenticationService.signOut();
    dispatch({type: 'SIGN_OUT_SUCCESS'});
    dispatch({type: 'RESET_USER'});
    dispatch({type: RESET_STUDENT_STATE});
  } catch (error) {
    dispatch({type: SIGN_OUT_ERROR, payload: error});
    dispatch({type: 'RESET_USER'});
    dispatch({type: RESET_STUDENT_STATE});
  }
};

export const doSignInWithPartner =
  (navState: any, partnerAppName: PikaDoPartners) =>
  async (dispatch: any, getState: any) => {
    try {
      console.log('navState.url->', navState.url);

      const arrState = navState.url.split('?');
      console.log('arrState>1', arrState);

      const tokenFounded = arrState?.find((value: string) =>
        value.startsWith('token:'),
      );

      console.log('tokenFounded', tokenFounded);

      const token = tokenFounded?.split(':')[1];

      console.log('token', token);

      const myDecodedToken: any = decodeToken(token);

      let partnerId = myDecodedToken?.student_id;

      console.log('studentIdFounded', myDecodedToken);

      if (arrState && partnerId) {
        dispatch(isLoadingLogInRequest(true));
        configAmplifyLambdaRequest();
        const resp: AuthenticatorResponse =
          await authenticationService.validatePartnerAuthUser(
            partnerAppName,
            partnerId,
          );
        const myDecodedTokenAuth: any = decodeToken(resp?.token ?? '');

        const partUser: PartnerUser = {
          partnerAppName,
          sharedStudentId: partnerId,
          isAuthenticated: true,
          cognitoUserPartnerId: myDecodedTokenAuth?.user?.cognitoUserId,
          token: resp.token,
        };
        dispatch(setSignInPartner(partUser));

        configAmplifyLambdaRequest();
        /**
         * We need to add rememberDevice(), to keep the auth session on the user's device.
         * Without it the user will logout after any refresh.
         * We commented it, as when it's active the auth. will fail and the user cannot log in.
         * */
        // await rememberDevice();

        await dispatch(getUser());

        console.log('partUser', partUser);


        await dispatch(fetchStudents());
        const studentsDetails = getState().student.studentsDetails;
        await dispatch(setSelectedStudent(studentsDetails[0]));
        await dispatch(fetchSelectedStudent());
        await dispatch(fetchProgramsDetails());
        await dispatch(getStudentTodayPlan());

        dispatch({type: IS_LOGGED_IN, payload: true});
      }
      console.log(arrState);

      dispatch(isLoadingLogInRequest(false));
    } catch (error: any) {
      dispatch(doSignOut());
      console.log('SIGN_IN_ERROR2', error);
      setTimeout(() => {
        window.location.href = '/';
      }, 3200);

      dispatch(isLoadingLogInRequest(false, error));

      notification.error({
        placement: 'top',
        duration: 10,
        message: error.errors[0].message,
      });
    }
  };

/*//TODO: handle clean the state after registering
export const doSignUp =
  (email: string, password: string, country: string, whatsappNumber: string) =>
  async (dispatch: any) => {
    configAmplifyCognitoRequest();
    dispatch(isLoadingRequest(true));

    try {
      await authenticationService.signUp(email, password);
      let signInResponse = await authenticationService.signIn(email, password);

      dispatch(createUser(signInResponse?.username, country, whatsappNumber));
      dispatch({type: SIGN_UP_SUCCESS});
      dispatch({type: SIGN_IN_SUCCESS, payload: signInResponse});
      dispatch(isLoadingRequest(false));
    } catch (error: any) {
      console.warn('error_sign_up', error);
      dispatch(isLoadingRequest(false, error));
    }
  };*/

function selectPartnerUser(state: any): PartnerUser {
  return state.auth.partnerUser;
}

export function getPartnerAuthToken(): string | undefined {
  let partnerUsr: PartnerUser = selectPartnerUser(store.getState());
  const authToken: string =
    'partner_auth-' + partnerUsr?.token ?? partnerUsr?.partnerAppName;

  return partnerUsr?.isAuthenticated ? authToken : undefined;
}

export function getPartnerCognitoId(): string {
  let partnerUsr: PartnerUser = selectPartnerUser(store.getState());
  const partnerCognitoId: string = partnerUsr?.cognitoUserPartnerId ?? '';

  return partnerUsr?.isAuthenticated ? partnerCognitoId : '';
}

export function configAmplifyLambdaRequest() {
  Amplify.configure({
    ...awsmobile,
    auth: {
      //@ts-ignore
      type: 'CUSTOM_AUTH',
      jwtToken: getPartnerAuthToken(),
    },
    aws_appsync_authenticationType: 'AWS_LAMBDA',
  });
}

export function configAmplifyCognitoRequest() {
  Amplify.configure({
    ...awsmobile,
  });
}
