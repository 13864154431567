import {IUserRepository} from './interfaces';
import * as customQueries from '../customQueries';
import {getPartnerAuthToken, getPartnerCognitoId} from '../../redux/actions';
import {generateClient, GraphQLResult} from 'aws-amplify/api';
const client = generateClient();
//createUserCustom
export class UserRepository implements IUserRepository {
  async getCurrentUser(): Promise<any> {
    if (getPartnerCognitoId()) {
      let response: GraphQLResult<any> = await client.graphql(
        {
          query: customQueries.customMe,
          variables: {
            cognitoUserId: getPartnerCognitoId(),
          },
          authToken: getPartnerAuthToken(),
        },
        {
          identity: getPartnerCognitoId(),
        },
      );
      return response;
    } else {
      let response: GraphQLResult<any> = await client.graphql({
        query: customQueries.customMe,
        variables: {},
        authToken: getPartnerAuthToken(),
      });
      return response;
    }
  }

  async getUserStudents(): Promise<any> {
    //return await API.graphql(graphqlOperation(queries.));
  }

  async createUser(
    cognitoUserId: String,
    country: string,
    whatsappNumber: string,
  ): Promise<any> {
    let response: GraphQLResult<any> = await client.graphql({
      query: customQueries.createUserCustom,
      variables: {
        input: {
          cognitoUserId: cognitoUserId,
          country: country,
          whatsappNumber: whatsappNumber,
        },
      },
    });

    return response;
  }
}
